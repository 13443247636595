@import 'mixins';


::selection{
    background-color: $main;
    color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
    &::-webkit-scrollbar{
        // width: 0px;
    }
}

body{
    // background-color: darken(white, 2%);
    // background-color: lighten(lavender, 3%);
    background-color: $lav;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.error{
    text-align: center;
    max-width: 300px;
    // background-color: orange;
    margin-top: 5px;
    font-weight: 300;
    min-height: 20px;
}

h2{
    width: calc(100% - 10px);
    padding: 0px 5px 0px 5px;
    @include flexColumn(flex-start);
    align-items: center;
    font-weight: 400;
    // letter-spacing: ;
    font-size: 30px;
    color: black;
    text-align: center;
}

.breadcrumbs{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: $main;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        list-style: none;
    }

    &__item{
        text-align: center;
        &:last-child{
            pointer-events: none;
        }
        &:not(:first-child){
            margin-left: 5px;
        }
    }
    
    &__separator{
        margin: 0px 5px 0px 5px;
        color: white;
    }
    
    &__link{
        text-decoration: none;
        color: white;
        transition: 0.3s;
        &:hover{
            text-decoration: underline;
        }
    }
}



.main-section{
    width: 100%;
    height: 300px;
    // background-color: red;
    @include flexRow(center);
    background-image: url(./../src/img/main-section-1.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;

    .disabled-sort{
        pointer-events: none;
        background-color: darken(white, 10%);
    }

    &__cover{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.178);
        position: absolute;
    }

    &__container{
        z-index: 1;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }

    &__header{
        font-size: 36px;
        color: white;
        text-align: center;
    }
    
    &__sort-container{
        // background-color: red;
        @include flexRow(center);
    }

    &__sort-block{
        margin: 10px;
        // background-color: yellow;
        position: relative;
    }
    
    &__sort-first-item{
        // background-color: red;
        border-radius: 10px;
        background-color: white;
        width: 280px;
        padding: 0px 10px 0px 10px;
        height: 40px;
        @include flexColumn(center);
        text-align: center;
        cursor: pointer;
    }

    &__sort-first-item_active{
        border-radius: 10px 10px 0px 0px;
    }

    &__sort-menu{
        position: absolute;
        width: 100%;
        background-color: white;
        list-style: none;
        @include flexColumn(flex-start);
        max-height: 290px;
        padding: 0px 0px 10px 0px;
        border-radius: 0px 0px 10px 10px;
        overflow-y: scroll;
        display: none;
        &::-webkit-scrollbar{
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $main;
        }
    }

    &__sort-menu-item{
        width: calc(100% - 20px);
        padding: 5px 10px 5px 10px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken(white, 5%);
        }
    }

    &__sort-button{
        width: 300px;
        height: 40px;
        background-color: $main;
        margin: 10px;
        border-radius: 10px;
        color: white;
        cursor: pointer;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        transition: 0.3s;
        &:hover{
            background: darken($main, 5%);
        }
    }
}

.stat-section{
    width: 100%;
    // height: 130px;
    padding: 40px 0px;
    // background-color: red;
    // background-color: darken($main, 5%);
    @include flexRow(center);
    
    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }
    
    &__stat{
        // background-color: darken($main, 5%);
        width: 220px;
        height: 100px;
        // background-color: pink;
        @include flexColumn(flex-start);
        margin: 10px;
        // border-radius: 10px;
        position: relative;

        &::after{
            display: block;
            content: "";
            background-size: 100%;
            background-repeat: no-repeat;
            // object-fit: cover;
            width: 150px;
            height: 150px;
            position: absolute;
            left: calc(50% - 75px);
            top: calc(50% - 55px);
            z-index: -1;
            // mix-blend-mode: difference;
            // top: 0px;
        }

        &:nth-child(1){
            &::after{
                background-image: url(./../src/img/after-1.svg);
            }
        }
        &:nth-child(2){
            &::after{
                background-image: url(./../src/img/after-2.svg);
            }
        }
        &:nth-child(3){
            &::after{
                background-image: url(./../src/img/after-3.svg);
            }
        }
        &:nth-child(4){
            &::after{
                background-image: url(./../src/img/after-4.svg);
            }
        }
    }

    &__value{
        width: 100%;
        height: 70px;
        // background-color: plum;
        // color: white;
        text-align: center;
        @include flexColumn(center);
        font-size: 44px;
        // z-index: 2;
    }
    
    &__name{
        // z-index: 100;
        // color: white;
        width: 100%;
        height: 30px;
        // margin-top: 10px;
        // background-color: plum;
        text-align: center;
        @include flexColumn(center);
        font-size: 20px;
        // padding: 0px 0px 10px 0px;
    }
}

.specs-section{
    width: 100%;
    padding: 50px 0px;
    margin-top: 40px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__specs-container{
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        // margin: -10px;
        margin-top: 30px;
    }

    &__spec{
        width: 320px;
        margin: 10px;
        height: 150px;
        // background-color: plum;
        text-decoration: none;
        // background-color: white;
        // align-items: center;
        // margin-top: 12px;
        color: black;
        // @include flexRow(flex-start);
        @include flexColumn(flex-start);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.466);
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 4px 7px rgba(2, 189, 248, 0.21);
            .specs-section__name{
                color: $main;
            }
        }
    }
    
    &__content-block{
        width: 100%;
        height: 60px;
        // background-color: orange;
        @include flexRow(flex-start);
    }

    &__img-block{
        width: 60px;
        height: 60px;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        user-select: none;
        max-width: 70%;
        max-height: 70%;
    }

    &__name{
        user-select: none;
        transition: 0.3s;
        // background-color: aqua;
        // width: 200px;
        width: calc(100% - 20px);
        margin-left: 10px;
        @include flexColumn(center);
        // text-align: center;
        padding: 5px;
        height: calc(100% - 10px);
        // margin-top: 10px;
        // max-height: 60px;
        font-size: 16px;
        font-weight: 500;
        // display: none;
    }

    &__cats-container{
        width: calc(100% - 20px);
        // width: 100%;
        height: 85px;
        padding: 0px 10px 0px 10px;
        margin-top: 5px;
        display: block;

    }   

    &__cat-link{
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        color: $main;
        transition: 0.3s;
        margin-right: 3px;
        &:hover{
            color: $admin-blue;
        }

        &:last-child{
            white-space: nowrap;
            color: black;
            transition: 0.3s;
            &:hover{
                text-decoration: underline;
            }
        }
    }

    &__buttons-block{
        width: 100%;
        // background-color: pink;
        margin-top: 10px;
        @include flexRow(center);
    }

    &__big-button{
        padding: 10px;
        text-decoration: none;
        color: black;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.116);
        border-radius: 5px;
        transition: 0.3s;
        font-weight: 300;
        &:hover{
            box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.116), 0px 0px 0px 7px rgba(0, 0, 0, 0.116);
        }
    }
}

.top-section{
    width: 100%;
    // height: 600px;
    // background-color: red;
    padding: 40px 0px 40px 0px;
    background-color: darken(white, 5%);
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        @include flexColumn(flex-start);
        // background-color: yellow;
    }

    &__header{
        width: calc(100% - 10px);
        padding: 0px 5px 0px 5px;
        @include flexColumn(flex-start);
        align-items: center;
        font-weight: 400;
        // letter-spacing: ;
        font-size: 30px;
        color: black;
        text-align: center;
        // background-color: pink;
    }

    &__servs-container{
        width: 100%;
        // height: 600px;
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__serv{
        max-width: 480px;
        width: 100%;
        height: 100px;
        padding: 10px;
        // background-color: plum;
        margin: 20px;
        @include flexRow(flex-start);
        background-color: white;
        align-items: center;
        text-decoration: none;
        color: black;
        box-shadow: 0px 0px 1px 0px black;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 0px 1px black;
        }
    }

    &__logo-block{
        width: 100px;
        height: 100px;
        // background-color: orange;
    }

    &__logo{
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    &__content-block{
        max-width: 360px;
        width: 100%;
        height: 100px;
        // background-color: orange;
        margin-left: 20px;
        @include flexColumn(flex-start);
    }

    &__serv-name{
        overflow: hidden;
        width: 100%;
        min-height: 30px;
        // background-color: aqua;
        font-size: 20px;
        font-weight: 500;
    }

    &__contact-block{
        width: 100%;
        height: 20px;
        // background-color: aqua;
        margin-top: 5px;
        @include flexRow(flex-start);
    }

    &__img-block{
        width: 20px;
        height: 20px;
        // background-color: palevioletred;
    }

    &__img{
        width: 20px;
        height: 20px;
    }
    
    &__contact-value{
        font-size: 14px;
        margin-left: 10px;
    }

    &__spec-block{
        // font-size: 14px;
        width: 100%;
        height: 20px;
        // background-color: aqua;
        margin-top: 10px;
        @include flexRow(flex-start);
    }
}

.text-section{
    width: 100%;
    // height: 300px;
    // background-color: red;
    // background-color: darken($main, 15%);
    background: rgb(1,131,172);
    background: -moz-linear-gradient(90deg, rgba(1,131,172,1) 13%, rgba(0,194,255,1) 60%);
    background: -webkit-linear-gradient(90deg, rgba(1,131,172,1) 13%, rgba(0,194,255,1) 60%);
    background: linear-gradient(90deg, rgba(1,131,172,1) 13%, rgba(0,194,255,1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0183ac",endColorstr="#00c2ff",GradientType=1);
    padding: 20px 0px 0px 0px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // height: 300px;
        // background-color: yellow;
        @include flexRow(center);
    }

    &__text-block{
        max-width: 600px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }

    &__header{
        width: 100%;
        height: 60px;
        // background-color: plum;
        color: white;
        font-size: 30px;
        @include flexColumn(center);
    }

    &__text{
        color: white;
        width: 100%;
        height: 200px;
        // background-color: plum;
        margin-top: 20px;
        // font-size: 18px;
        line-height: 24px;
    }

    &__img-block{
        height: 220px;
        width: 234px;
        // background-color: pink;
        margin-left: 50px;
    }

    &__img{
        width: 220px;
        height: 280px;
    }
}

.brands-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__brands-container{
        margin-top: 30px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__brand{
        padding: 5px;
        width: 180px;
        min-height: 60px;
        // background-color: plum;
        margin: 5px;
        @include flexColumn(center);
        text-decoration: none;
        color: black;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.466);
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 4px 7px rgba(2, 189, 248, 0.21);
            .brands-section__name{
                color: $main;
            }
        }
    }

    &__img-block{
        width: 100%;
        height: 80px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        max-width: 90%;
        max-height: 90%;
    }

    &__name{
        transition: 0.3s;
        // margin-top: 5px;
        // background-color: orange;
        text-align: center;
        font-weight: 400;
    }

    &__buttons-block{
        width: 100%;
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
    }

    &__big-button{
        padding: 10px;
        text-decoration: none;
        color: black;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.116);
        border-radius: 5px;
        transition: 0.3s;
        font-weight: 300;
        &:hover{
            box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.116), 0px 0px 0px 7px rgba(0, 0, 0, 0.116);
        }
    }
}

.action-modal{
    width: 100vw;
    height: 100vh;
    background-color: transparentize(black, 0.5);
    position: absolute;
    top: 0px;
    @include flexRow(center);
    z-index: 2;
    display: none;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }

    #consul-block{
        // display: none;
    }

    &__modal-block{
        background-color: white;
        border-radius: 3px;
        padding: 20px;
        width: 260px;
        // height: 400px;
        // background-color: pink;
        position: relative;
        @include flexColumn(flex-start);
    }

    &__close-button{
        position: absolute;
        width: 30px;
        // height: 30px;
        // background-color: plum;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 35px);
        bottom: calc(100% - 35px);
        cursor: pointer;
        transition: 0.3s;
        svg{
            transition: 0.3s;
            path{
                fill: $admin-blue;
            }
        }
        &:hover{
            svg{
                transform: rotate(180deg);
            }
        }
    }

    &__modal-header{
        width: 100%;
        // height: 40px;
        // background-color: plum;
        font-size: 26px;
    }

    &__form{
        width: 100%;
        // height: 300px;
        // background-color: plum;
        margin-top: 15px;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__input{
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 4px;
        border: none;
        outline: none;
        background-color: darken(white, 5%);
        box-shadow: 0px 2px 0px 0px $admin-blue;
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__form-button{
        width: 250px;
        height: 40px;
        margin-top: 30px;
        background-color: $main;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        transition: 0.3s;
        &:hover{
            background-color: $admin-blue;
        }
    }

    &__error{
        margin-top: 10px;
        // background-color: orange;
        width: 250px;
        min-height: 30px;
        text-align: center;
        word-wrap: break-word;
    }
}

.cats-section{
    width: 100%;
    // height: 200px;
    padding: 40px 0px 40px 0px;
    // background-color: red;
    background-color: darken(white, 5%);
    @include flexRow(center);

    &__container{
        max-width: 1000px;
        width: 100%;
        // background-color: yellow;
        // height: 100%;
        @include flexColumn(flex-start);
    }

    &__cat{
        width: 100%;
        min-height: 200px;
        // background-color: white;
        margin-bottom: 30px;
        @include flexRow(flex-start);
        border-radius: 10px 0px 0px 10px;
        
        &:nth-child(even){
            // background-color: red;

            .cats-section__img-block{
                order: 2;
                border-radius: 0px 10px 10px 0px;
            }
            // background-color: yellow;
        }
    }

    &__img-block{
        border-radius: 10px 0px 0px 10px;
        width: 200px;
        height: 100%;
        background-color: $main;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        max-width: 60%;
        max-height: 60%;
    }

    &__content-block{
        // margin-left: 30px;
        max-width: 760px;
        width: 100%;
        // height: calc(100% - 10px);
        padding: 10px 10px 10px 30px;
        background-color: white;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__cat-header{
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        // background-color: yellow;
    }

    &__small-cats-container{
        width: 100%;
        // height: 100px;
        // background-color: yellow;
        // overflow: hidden;
        // overflow-y: auto;
        @include flexRow(flex-start);
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    &__small-cat{
        margin: 10px;
        // background-color: pink;
        color: black;
        font-size: 14px;
        &:hover{
            text-decoration: none;
        }
    }

    &__all-button{
        // width: 100%;
        height: 30px;
        padding: 5px;
        background-color: $main;
        position: absolute;
        bottom: 5px;
        border-radius: 5px;
        // left: 0px;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        color: white;
        transition: 0.3s;
        &:hover{
            text-decoration: underline;
        }
    }
}

.brands-section{
    // background-color: red;
    @include flexRow(center);

    &__container{
        // background-color: yellow;
        max-width: 1200px;
        width: 100%;
        @include flexRow(center);
        flex-wrap: wrap;
    }
}

.servs-section{
    width: 100%;
    // height: 100px;
    // background-color: red;
    background-color: darken(white, 5%);
    padding: 40px 0px 40px 0px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // @include fle
    }

    &__sub-header{
        font-size: 20px;
        font-weight: 400;
        // margin-top: 15px;
        // background-color: olive;
    }

    &__brands-block{
        width: calc(100% - 20px);
        // height: 200px;
        padding: 10px;
        background-color: white;
        margin-top: 30px;
        @include flexColumn(flex-start);
        border-radius: 10px;
    }

    &__brands-header-block{
        width: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
    }

    &__brands-header{
        font-size: 18px;
    }

    &__brands-button{
        height: 30px;
        background-color: $main;
        padding: 5px 10px 5px 10px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        border-radius: 10px;
        transition: 0.3s;
        cursor: pointer;
        width: 200px;
        &:hover{
            background-color: darken($main, 5%);
        }
    }

    &__brands-container{
        margin-top: 10px;
        width: 100%;
        max-height: 180px;
        // background-color: yellow;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        overflow: hidden;
    }

    &__brand{
        padding: 10px;
        // background-color: pink;
        text-decoration: none;
        color: black;
        box-shadow: 0px 0px 0px 1px $main;
        border-radius: 10px;
        transition: 0.3s;
        margin: 10px;
        &:hover{
            background-color: $main;
            color: white;
        }
    }

    &__servs-container{
        // background-color: pink;
        width: 100%;
        @include flexRow(center);
        // align-items: center;
        padding: 40px 0px 40px 0px;
        position: static;
    }

    &__servs-block{
        // background-color: plum;
        max-width: 830px;
    }

    &__form-block{
        width: 270px;
        padding: 15px;
        height: 370px;
        // background-color: plum;
        background-color: white;
        margin-left: 30px;
        border-radius: 5px;
        position: sticky;
        @include flexColumn(flex-start);
        align-items: center;
        // right: 0px;
    }

    &__form{
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__form-header{
        font-size: 24px;
        text-align: center;
        margin-top: 10px;
    }

    &__from{
        svg{
            width: 60px;
            height: 60px;
        }
    }

    &__input{
        width: calc(100% - 12px);
        height: 38px;
        padding: 0px 0px 0px 10px;
        border: 1px solid #02bdf8;
        border-radius: 5px;
        margin-bottom: 10px;
        outline: none;
    }
    
    &__form{
        .form-error{
            width: 100%;
            height: 40px;
            // background-color: plum;
            text-align: center;
            @include flexColumn(center);
            text-align: center;
            font-size: 14px;
            display: none;
        }
    }

    &__button{
        width: 100%;
        height: 40px;
        background-color: #02bdf8;
        color: white;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        border: none;
        outline: none;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
    }

    &__metro-block{
        width: calc(100% - 20px);
        // height: 200px;
        padding: 10px;
        background-color: white;
        margin-top: 30px;
        @include flexColumn(flex-start);
        border-radius: 10px;
    }

    &__metro-header-block{
        width: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
    }
    
    &__metro-header{
        font-size: 18px;
    }
    
    &__metro-show-button{
        height: 30px;
        background-color: $main;
        padding: 5px 10px 5px 10px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        border-radius: 10px;
        transition: 0.3s;
        cursor: pointer;
        width: 200px;
        &:hover{
            background-color: darken($main, 5%);
        }
    }
    
    &__metro-container{
        margin-top: 10px;
        width: 100%;
        max-height: 160px;
        // background-color: yellow;
        @include flexRow(flex-start);
        flex-wrap: wrap;
        overflow: hidden;
    }
    
    &__metro{
        text-decoration: none;
        color: black;
        transition: 0.3s;
        margin: 10px;
        @include flexRow(flex-start);
        align-items: center;
        &:hover{
            text-decoration: underline;
        }
    }

    &__metro-circle{
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 10px;
        margin-right: 5px;
    }
}

.serv{
    width: 800px;
    // height: 300px;
    padding: 15px;
    border-radius: 5px;
    @include flexColumn(flex-start);
    // margin-top: 30px;
    position: relative;
    background-color: white;
    // background-color: red;
    transition: 0.3s;
    &:not(:first-child){
        margin-top: 30px;
    }
    &:hover{
        box-shadow: 0px 0px 10px transparentize($main, 0.5);
        transform: scale(1.05);
    }

    &__outline{
        box-shadow: 0px 0px 5px 1px $main;
    }

    &__main-container{
        width: 100%;
        height: 110px;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__logo-block{
        width: 110px;
        height: 110px;
        // background-color: pink;
        @include flexColumn(center);
        align-items: center;
    }

    &__logo{
        max-width: 100%;
        max-height: 100%;
    }

    &__main-info-block{
        width: 330px;
        height: 110px;
        // background-color: pink;
        // margin-left: 5px;
        @include flexColumn(space-between);
        align-items: flex-start;
    }

    &__name{
        text-decoration: underline;
        max-width: 100%;
        height: 30px;
        // background-color: plum;
        cursor: pointer;
        @include flexColumn(center);
        font-size: 24px;
        font-weight: 500;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
            color: $main;
        }
        &:visited{
            color: black;
        }
    }

    &__stars-container{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexRow(flex-start);
    }

    &__star{
        margin-right: 5px;
    }

    &__star_active{
        path{
            fill: $main;
        }
    }

    &__reviews-text{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexRow(flex-start);
        align-items: center;
        font-size: 20px;
        transition: 0.3s;
        &:hover{
            // transform: scale(1.1);
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__reviews-quantity{
        margin-left: 10px;
        font-size: 22px;
        color: $main;
        
    }

    &__owner-info-block{
        width: 250px;
        height: 110px;
        // background-color: pink;
        // margin-left: 70px;
        @include flexColumn(space-between);
    }

    &__owner-info{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexRow(space-between);
        align-items: center;
    }

    &__owner-circle{
        width: 12px;
        height: 12px;
        background-color: red;
        @include flexColumn(center);
        align-items: center;
        border-radius: 12px;
    }

    &__owner-circle_active{
        background-color: yellowgreen;
    }

    &__address-container{
        width: 100%;
        height: 30px;
        // background-color: yellow;
        @include flexRow(space-between);
        margin-top: 30px;
    }

    &__address-block{
        height: 30px;
        // background-color: pink;
        @include flexRow(flex-start);
    }

    &__city{
        // background-color: plum;
        @include flexColumn(center);
        font-weight: 500;
    }

    &__address{
        // background-color: plum;
        @include flexColumn(center);
        // font-weight: 500;
        margin-left: 10px;
    }

    &__station{
        // background-color: plum;
        @include flexRow(flex-start);
        margin-left: 15px;
        align-items: center;
    }

    &__station-circle{
        width: 8px;
        height: 8px;
        background-color: green;
        margin-right: 10px;
        border-radius: 10px;
    }

    &__button{
        width: 200px;
        height: 30px;
        background-color: $main;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        @include flexColumn(center);
        align-items: center;
        box-shadow: 0px 0px 0px 5px $main;
        transition: 0.3s;
        &:hover{
            background-color: darken($main, 5%);
            box-shadow: 0px 0px 0px 5px darken($main, 5%);
            transform: scale(1.05);
        }
    }

    &__info-container{
        width: 100%;
        height: 110px;
        // background-color: yellow;
        margin-top: 20px;
        @include flexRow(space-between);
    }

    &__contacts-container{
        width: 330px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }

    &__common-phone{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexColumn(center);
        text-decoration: none;
        color: black;
        font-size: 18px;
        font-weight: 500;
        transition: 0.3s;
        &:hover{
            // transform: scale(1.1);
            color: $main;
        }
    }

    &__phone{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexRow(flex-start);
        text-decoration: none;
        color: black;
        // font-size: 18px;
        // font-weight: 500;
        transition: 0.3s;
        &:hover{
            // transform: scale(1.1);
            b{
                color: $main;
            }
        }

        b{
            transition: 0.3s;
            margin-left: 5px;
        }
    }

    &__site{
        width: 100%;
        height: 30px;
        // background-color: plum;
        @include flexColumn(center);
        // text-decoration: none;
        color: black;
        // text-decoration: underline;
        transition: 0.3s;
        &:hover{
            color: $main;
        }
        // font-size: 18px;
        // font-weight: 500;
    }

    &__schedule-block{
        width: 290px;
        height: 100%;
        // background-color: pink;
        // margin-left: 10px;
        @include flexColumn(flex-start);
        flex-wrap: wrap;
    }

    &__schedule-item{
        margin: 5px;
        // background-color: plum;
        font-size: 14px;
    }

    &__spec-block{
        width: 100%;
        height: 30px;
        // background-color: yellow;
        margin-top: 10px;
        @include flexColumn(center);
    }
}

.serv-block{
    max-width: 100%;
    // height: 100vh;
    // background-color: red;
    @include flexRow(center);
    align-items: flex-start;
    padding: 30px 0px 30px 0px;
}

.serv-section{
    width: 800px;
    // padding: 10px;
    // height: 500px;
    // background-color: yellow;
    @include flexColumn(flex-start);
    // border-radius: 5px;

    &__main-block{
        width: calc(100% - 40px);
        padding: 20px;
        height: 400px;
        // background-color: pink;
        @include flexColumn(flex-start);
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
    }

    &__serv-info-block{
        width: 100%;
        height: 50px;
        // background-color: plum;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__uslugi-info-block{
        @include flexRow(space-between);
    }

    &__serv-name{
        // background-color: orange;
        width: 450px;
        text-align: start;
        align-items: flex-start;
        padding: 0px;
    }

    &__top-rating{
        // background-color: orange;
        width: unset !important;
        margin-top: 0px !important;
        margin-left: 20px;
    }
    
    &__reviews-link{
        // background-color: orange;
        margin-left: 20px;
        color: black;
        transition: 0.3s;
        // font-size: 18px;
        &:hover{
            color: $main;
        }
    }

    &__serv-map-block{
        width: 100%;
        height: 330px;
        // background-color: plum;
        margin-top: 20px;
    }

    &__uslugi-block{
        width: calc(100% - 40px);
        padding: 20px;
        // max-height: 680px;
        background-color: pink;
        @include flexColumn(flex-start);
        margin-top: 10px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
    }

    &__consul-button{
        width: 200px;
        height: 50px;
        background-color: $main;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 3px 2px $admin-blue;
            background-color: $admin-blue;
        }
    }

    &__uslugi-container{
        width: 100%;
        // max-height: 610px;
        // background-color: plum;
        margin-top: 20px;
    }

    &__uslugi-table{
        // background-color: orange;
        width: 100%;
        // max-height: 530px;
        @include flexColumn(flex-start);
        // overflow-y: scroll;

        // &::-webkit-scrollbar{
        //     width: 3px;
        // }

        // &::-webkit-scrollbar-thumb{
        //     background-color: $main;
        // }
    }
    
    &__headers-tr{
        width: 100%;
        @include flexRow(flex-start);
        background-color: $admin-blue;
        // background-color: aqua;
    }
    
    &__header-th{
        // width: 80%;
        color: white;
        // height: 100%;
        height: 40px;
        @include flexColumn(center);
        font-size: 18px;
        font-weight: 500;
        &:first-child{
            width: calc(80% - 10px);
            // background-color: yellowgreen;
            padding: 0px 5px 0px 5px;
        }
        &:last-child{
            text-align: center;
            width: calc(20% - 10px);
            // background-color: bisque;
            padding: 0px 5px 0px 5px;
        }
    }

    &__usluga-tr{
        width: 100%;
        min-height: 30px;
        // background-color: aqua;
        margin-top: 10px;
        @include flexRow(flex-start);
        &:nth-child(odd){
            background-color: darken(white, 5%);
        }
    }

    &__usluga-th{
        width: calc(80% - 10px);
        // background-color: yellowgreen;
        padding: 0px 5px 0px 5px;
        font-size: 18px;
        font-weight: 400;
        @include flexColumn(center);
    }
    
    &__usluga-price-th{
        text-align: center;
        width: calc(20% - 10px);
        // background-color: bisque;
        padding: 0px 5px 0px 5px;
        @include flexColumn(center);
        font-weight: 400;
    }

    &__reviews-block{
        width: calc(100% - 40px);
        padding: 20px;
        // height: 400px;
        // background-color: pink;
        @include flexColumn(flex-start);
        margin-top: 20px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
    }

    &__reviews-container{
        width: calc(100% - 40px);
        padding: 20px;
        // background-color: plum;
        margin-top: 20px;
        overflow-y: scroll;
        max-height: 600px;
        &::-webkit-scrollbar{
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $main;
        }
    }

    &__review-warn-text{
        // background-color: orange;
        font-size: 16px;
        padding: 20px;
        border: 1px solid $main;
        background-color: transparentize($main, 0.9);
        color: $admin-blue;
        text-align: center;
    }

    &__review-empty-text{
        // background-color: orange;
        margin-top: 20px;
        font-size: 22px;
        text-align: center;
    }

    &__review{
        width: calc(100% - 40px);
        // height: 300px;
        @include flexColumn(flex-start);
        &:not(:first-child){
            margin-top: 30px;
        }
    }

    &__reviewer-info-block{
        width: 100%;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__reviewer-name{
        font-size: 20px;

    }

    &__review-rating{
        width: 100%;
        height: 30px;
        // background-color: aqua;
        margin-top: 5px;
    }

    &__review-star_active{
        path{
            fill: $main;
        }
    }

    &__review-usluga{
        margin-top: 5px;
    }

    &__review-text{
        margin-top: 5px;
        max-height: 150px;
        // background-color: orange;
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main;
        }
    }
}

.menu-section{
    width: 400px;
    // height: 510px;
    margin-left: 30px;
    @include flexColumn(flex-start);
    padding: 10px 0px 10px 0px;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    // background-color: yellow;
    border-radius: 5px;
    box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);

    &__buttons-block{
        width: 100%;
        // height: 100px;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__call-button{
        width: 250px;
        height: 40px;
        background-color: $main;
        border-radius: 5px;
        @include flexColumn(center);
        text-align: center;
        color: white;
        text-decoration: none;
        font-size: 20px;
        transition: 0.3s;
        &:hover{
            background-color: $admin-blue;
        }
    }

    &__req-button{
        margin-top: 20px;
        width: 250px;
        height: 40px;
        background-color: darken(white, 5%);
        border-radius: 5px;
        @include flexColumn(center);
        text-align: center;
        color: black;
        text-decoration: none;
        font-size: 20px;
        transition: 0.3s;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
        &:hover{
            box-shadow: 0 1px 15px rgba(128, 128, 128, 0.5);
        }
    }

    &__address-block,
    &__work-time-block{
        width: calc(100% - 20px);
        // height: 200px;
        // background-color: pink;
        margin-top: 20px;
        padding: 10px;
        @include flexColumn(flex-start);
    }

    &__address-item{
        @include flexRow(flex-start);
        // background-color: orange;
        align-items: center;
        font-size: 18px;
        &:not(:first-child){
            margin-top: 5px;
        }
    }

    &__item-svg-container{
        // background-color: orange;
        width: 25px;
    }

    &__item-text{
        margin-left: 5px;
        // height: 100%;
    }

    &__address{
        font-weight: 600;
    }

    &__work-time-item{
        @include flexRow(flex-start);
        // background-color: plum;
        margin-bottom: 5px;
    }

    &__work-time-day{
        width: 120px;
    }

    &__write-review-button{
        width: 300px;
        height: 40px;
        background-color: $main;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        margin-top: 15px;
        transition: 0.3s;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
        &:hover{
            background-color: $admin-blue;
        }
    }

    &__write-warn-button{
        width: 300px;
        height: 40px;
        // background-color: $main;
        background-color: darken(white, 5%);
        border-radius: 5px;
        text-decoration: none;
        color: black;
        text-align: center;
        @include flexColumn(center);
        margin-top: 15px;
        transition: 0.3s;
        box-shadow: 0 1px 15px hsl(0deg 0% 86% / 50%);
        &:hover{
            box-shadow: 0 1px 15px rgba(128, 128, 128, 0.5);
        }
    }
}

#modals-block{
    display: none;
}

.modal-button{
    cursor: pointer;
}

.serv-modals-block{
    width: 100%;
    height: 100vh;
    // background-color: red;
    background-color: transparentize(black, 0.5);
    position: absolute;
    top: 0px;
    @include flexRow(center);
    display: none;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }

    #warn-block{
        display: none;
    }

    #review-block{
        display: none;
    }

    &__modal-block{
        background-color: white;
        border-radius: 3px;
        padding: 20px;
        width: 260px;
        // height: 400px;
        // background-color: pink;
        position: relative;
        @include flexColumn(flex-start);
    }

    &__close-button{
        position: absolute;
        width: 30px;
        // height: 30px;
        // background-color: plum;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 35px);
        bottom: calc(100% - 35px);
        cursor: pointer;
        transition: 0.3s;
        svg{
            transition: 0.3s;
            path{
                fill: $admin-blue;
            }
        }
        &:hover{
            svg{
                transform: rotate(180deg);
            }
        }
    }

    &__modal-header{
        width: 100%;
        // height: 40px;
        // background-color: plum;
        font-size: 26px;
    }

    &__form{
        width: 100%;
        // height: 300px;
        // background-color: plum;
        margin-top: 15px;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__input{
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 4px;
        border: none;
        outline: none;
        background-color: darken(white, 5%);
        box-shadow: 0px 2px 0px 0px $admin-blue;
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__select{
        width: 250px;
        height: 40px;
        box-shadow: 0px 2px 0px 0px $admin-blue;
        background-color: darken(white, 7%);
        margin-top: 10px;
        border: none;
        outline: none;
    }

    &__option{
        outline: none;
        border: none;
        box-shadow: none;
        @include flexRow(space-between);
        height: 40px;
        // background-color: red;
        // color: invert($color: #000000);
        color: white;
        // text-shadow: 1px 1px 2px black;
        font-size: 13px;
    }

    &__textarea{
        width: 230px;
        height: 50px;
        resize: none;
        outline: none;
        border: 1px solid $admin-blue;
        padding: 10px;
        margin-top: 10px;
    }

    &__form-button{
        width: 250px;
        height: 40px;
        margin-top: 30px;
        background-color: $main;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        transition: 0.3s;
        &:hover{
            background-color: $admin-blue;
        }
    }

    &__error{
        margin-top: 10px;
        // background-color: orange;
        width: 250px;
        min-height: 30px;
        text-align: center;
        word-wrap: break-word;
    }

    &__rating-stars-container{
        // width: 250px;
        margin-top: 5px;
        // background-color: pink;
        @include flexRow(flex-start);
        margin-bottom: 10px;
    }

    &__menu-label{
        width: 100%;
        height: 100%;
        // background-color: yellowgreen;
        @include flexRow(flex-start);
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            color: $main;
        }

    }
    
    &__menu-checkbox{
        display: none;
    }
    
    &__menu-item-text{
        @include flexRow(flex-start);
        align-items: center;
        font-size: 17px;
        // background-color: aqua;
        &::before{
            transition: 0.3s;
            margin-right: 10px;
            display: block;
            content: "";
            width: 28px;
            height: 28px;
            // background-color: red;
            border: 1px solid $admin-blue;
            // box-shadow: 0px 0px 0px 1px $admin-blue;
        }
    }

    &__menu-checkbox:checked + &__menu-item-text::before{
        background-color: $main;
    }

    &__star-label{
        cursor: pointer;
        &:hover{
            svg{
                path{
                    fill: $main;
                }
            }
        }
    }
    
    &__star-label_active{
        cursor: pointer;
        // margin-right: 13px;
        svg{
            path{
                fill: $main;
            }
        }
    }

    &__star-label_checked{
        pointer-events: none;
    }
    
    &__star-checkbox{
        display: none;
    }
    
    &__star-checkbox:checked + &__star-pseudo svg path{
        fill: $main;
    }
    
    &__star-pseudo{
        margin-right: 13px;
        // background-color: orange;
        width: 30px;
        height: 30px;
        svg{
            width: 100%;
            height: 100%;
            path{
                transition: 0.3s;
            }
        }
    }
}

@media (max-width: 1200px){

    .main-section{
        // display: none;
        height: unset;
        background-size: unset;


        &__sort-container{
            flex-wrap: wrap;
        }

        &__header{
            width: calc(100% - 20px);
            padding: 10px 10px 10px 10px;
        }
    }

    .breadcrumbs{
        &__container{
            @include flexRow(center);
            flex-wrap: wrap;
        }
    }

    .stat-section{
        // display: none;
        padding: 0px;
        &__stat{
            // background-color: red;
            margin: 40px;
        }
    }

    .specs-section{
        // display: none;
    }

    .top-section{
        // display: none;
    }

    .text-section{
        display: none;
        padding: 20px 0px 20px 0px;

        &__img-block{
            display: none;
        }

        &__header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__text{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            height: unset;
        }
    }

    .brands-section{
        // display: none;
    }

    .servs-section{
        // background-color: red;
        // display: none;
        &__sub-header{
            text-align: center;
        }

        &__servs-container{
            // background-color: pink;
            @include flexColumn(flex-start);
            align-items: center;
        }

        &__servs-block{
            max-width: 100%;
            width: 100%;
            order: 2;
            // background-color: plum;
            @include flexColumn(flex-start);
        }

        &__form-block{
            order: 1;
            margin: 0px;
            margin-bottom: 40px;
        }

        &__brands-block{

        }

        &__brands-header-block{
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__brands-header{
            margin: 10px;
        }

        &__brands-button{
            margin: 10px;
        }

        &__metro-block{

        }

        &__metro-header-block{
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__metro-header{
            margin: 10px;
        }

        &__metro-button{
            margin: 10px;
        }

        &__metro-container{
            @include flexRow(center);
            flex-wrap: wrap;
        }
    }

    .serv{
        // width: 100% !important;
        // height: unset;
        @include flexColumn(flex-start);
        width: calc(100% - 30px);
        
        &__main-container{
            align-items: center;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;
            height: unset;
            text-align: center;
        }
        
        &__main-info-block{
            // background-color: yellow;
            align-items: center;
        }

        &__stars-container{
            align-items: center;
            justify-content: center;
        }

        &__reviews-text{
            justify-content: center;
        }

        &__logo-block{
            // background-color: pink;
            align-items: center;
            justify-content: center;
        }

        &__address-container{
            // background-color: pink;
            height: unset;
            flex-wrap: wrap;
            justify-content: center;
            // align-items: center;
            // display: none;
        }
        
        &__address-block{
            justify-content: center;
            flex-wrap: wrap;
            height: unset;
        }

        &__info-container{
            // display: none;
            @include flexColumn(flex-start);
            height: unset;
            align-items: center;
        }

        &__contacts-container{
            width: 100%;
            align-items: center;
            align-content: center;
        }

        &__phone{
            text-align: center;
            justify-content: center;
        }

        &__site{
            text-align: center;
        }

        &__schedule-block{
            width: unset;
        }
    }

    .serv-block{
        // background-color: red;
        flex-wrap: wrap;
        padding: 0px;

        .serv-section{   
            // order: 2;
            &__main-block{
                // background-color: yellow;
                height: unset;
            }

            &__serv-info-block{
                flex-wrap: wrap;
                justify-content: center;
                height: unset;
                // background-color: pink;
            }

            &__serv-name{
                width: calc(100% - 10px);
                padding: 0px 5px 0px 5px;
                text-align: center;
                // background-color: plum;
                justify-content: center;
                @include flexRow(center);
                margin-bottom: 10px;
            }

            &__consul-button{
                // margin: 10px;
            }

            &__uslugi-block{
                max-height: unset;
            }

            &__uslugi-info-block{
                justify-content: center;
            }

            &__uslugi-container{
                max-height: unset;
            }
            
            &__uslugi-table{
                overflow-y: hidden;
                max-height: unset;
            }

            &__headers-tr{
                border-radius: 5px;
                // background-color: red;
                flex-wrap: wrap;
                justify-content: center;
                height: unset;
            }

            &__header-th{
                text-align: center;
                &:last-child{
                    width: 100%;
                }
            }

            &__usluga-tr{
                flex-wrap: wrap;
            }

            &__usluga-th{
                width: 100%;
                text-align: center;
            }

            &__usluga-price-th{
                width: 100%;
                text-align: center;
                align-items: center;
                align-content: center;
                font-weight: 500;
            }
        }

        .menu-section{
            width: 100%;
            margin: 30px 0px 0px 0px;
            // order: 1;
            // display: none;

            &__address-block{
                margin-top: 0px;
            }
        }
    }
}

.error-block{
    width: 100%;
    height: 100vh;
    // background-color: red;
    @include flexColumn(center);
    align-items: center;

    &__text{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        font-size: 64px;
        text-align: center;
    }
    
    &__sub-text{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        font-size: 34px;
        text-align: center;
    }

    &__link{
        font-size: 24px;
        color: $main;
        &:hover{
            text-decoration: none;
        }
    }
}

